import React from 'react';
import { Alert, SideSheet } from 'evergreen-ui';
import { Col, Row } from 'antd';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';
import useAxios from 'axios-hooks';
import { BASE_URL } from '../../../constants';
import Cookies from 'js-cookie';
import { toaster } from 'evergreen-ui';

function UpdateAgencyPreferences({ onCloseComplete, isShown, agencyData }) {
  const [formData, setFormData] = React.useState({
    minUnitThreshold: agencyData.preference.minUnitThreshold,
    receiptTypeId: agencyData.preference.receiptTypeId,
    receiptLength: agencyData.preference.receiptLength,
    emailReceipt: agencyData.preference.emailReceipt,
    smsReceipt: agencyData.preference.smsReceipt,
  });

  const [{ loading, data, error, response }, updateAgency] = useAxios(
    {
      url: `${BASE_URL}/api/agencies/${agencyData.id}/preferences`,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      method: 'put',
      data: {
        ...formData,
      },
    },
    { manual: true },
  );

  if (data) {
    toaster.success('Agency updated successfully');
    onCloseComplete(formData);
  }

  if (response) {
    console.log('This is response', response);
    if (response.status === 204) {
      toaster.success('Agency updated successfully');
      onCloseComplete(formData);
    }
  }

  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      <div style={{ margin: '20px' }}>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Update {agencyData.name}</h3>
        </div>
        <div style={{ marginTop: '40px' }}>
          {error ? (
            <Alert intent="danger">
              <p>{error.response.data}</p>
            </Alert>
          ) : null}

          <h5>Agency Preferences</h5>
          <form
            className="mt-8"
            onSubmit={(e) => {
              e.preventDefault();
              updateAgency();
            }}
          >
            <Row gutter={5}>
              <Col span={24}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Minimum Unit Threshold
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextInput
                    required
                    type="number"
                    min="1"
                    value={formData.minUnitThreshold}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        minUnitThreshold: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              {/*<Col span={16}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Receipt Type ID
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextInput
                    required
                    type="number"
                    value={formData.receiptTypeId}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        receiptTypeId: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>*/}

              <Col span={24}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Receipt Length
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextInput
                    required
                    type="number"
                    min="1"
                    value={formData.receiptLength}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        receiptLength: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <Col span={24}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      display: 'flex',
                      width: '300px',
                      flexDirection: 'row',
                    }}
                  >
                    <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2 mr-5">
                      Email Enabled
                    </label>
                    <div className="rounded-md shadow-sm mb-5">
                      <input
                        type="checkbox"
                        checked={formData.emailReceipt}
                        style={{ flex: 1 }}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            emailReceipt: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      width: '300px',
                      flexDirection: 'row',
                    }}
                  >
                    <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2 mr-5">
                      SMS Enabled
                    </label>
                    <div className="rounded-md shadow-sm mb-5">
                      <input
                        type="checkbox"
                        checked={formData.smsReceipt}
                        style={{ flex: 1 }}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            smsReceipt: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>

              <Col span={24}>
                <Button type="submit">{loading ? 'Loading..' : 'Update Agency Preferences'}</Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </SideSheet>
  );
}

export default UpdateAgencyPreferences;
