import React, { useEffect, useState } from 'react';
import C3Chart from 'react-c3js';
import { Line } from 'react-chartjs-2';

import axios from 'axios';
import Cookies from 'universal-cookie';

import 'c3/c3.css';
import { Card, Col, Row, Table, Spin } from 'antd';
import { BASE_URL,REACT_APP_API_MDA_WEBSOCKET_BASE_URL } from './constants';

// hooks
import * as SignalR from '@aspnet/signalr';

  const columns = [
  {
    title: 'Name of Agency',
    dataIndex: 'agencyName',
    key: 'agencyName',
  },
  {
    title: 'Number of Receipts',
    key: 'receiptCount',
    dataIndex: 'receiptCount',
    render: (receiptCount) => <span style={{ fontWeight: 'bold' }}>{receiptCount}</span>,
  },
  {
    title: 'Total Amount',
    key: 'receiptTotal',
    dataIndex: 'receiptTotal',
    align:'right',
    render: (receiptTotal) => (
      <span style={{ fontWeight: 'bold' }}>
        GHc{' '}
        {String(
          Number(parseFloat(receiptTotal).toFixed(2)).toLocaleString('en', {
            minimumFractionDigits: 2,
          }),
        )}
      </span>
    ),
  },
];



const cookies = new Cookies();
const baseURL = BASE_URL;
const signalRURL= REACT_APP_API_MDA_WEBSOCKET_BASE_URL;
const header = {
  Authorization: 'Bearer ' + cookies.get('token'),
};


class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      weeklyData:{},
      topNAgencyData:[],
      MDAConnectionState:false,
      portalConnectionState:false
    };
  }

  componentDidMount(){
    Promise.all([this.dashboard().topNAgency(), this.dashboard().weeklyTotals()]).then((response) => {
      this.setState({
        topNAgencyData:response[0].data,
        weeklyData:response[1].data,
        loading:false
      });
    });

    /*componentWillUnmount()
    {
      this.props.connection.off('mda',()=>{
        console.log("closed");
      })
    }*/

    /*connection.onclose(async () => {
      this.setState({MDAConnectionState:false});
      await start();
    });*/
  }

  dashboard(){
    return {
      topNAgency: () =>
        axios.get(baseURL + '/api/reports/topNAgencyReceiptCount', {
          params: {},
          headers: {
            Authorization: 'Bearer ' + cookies.get('token'),
          },
        }),
      weeklyTotals: () =>
        axios.get(baseURL + '/api/reports/weeklyTotals', { params: {}, headers: {
            Authorization: 'Bearer ' + cookies.get('token'),
          }
        }),
    }
  };

  addMDAListner=(receipt)=>{

      const {weeklyData,topNAgencyData}=this.state
      let newWeeklyData = {
        receiptCount: [...weeklyData.receiptCount],
        receiptTotal: [...weeklyData.receiptTotal],
      };

      let newTopNAgencyData = "";
      let newTopNAgencyDataTransfer = topNAgencyData;
      let agency="";
      for (let i=0;i<topNAgencyData.length;i++) {
        agency = topNAgencyData[i];

        if (agency.agencyName === receipt.notificationDetails.agencyName) {
          newTopNAgencyData = {
            agencyName: agency.agencyName,
            receiptTotal: agency.receiptTotal + receipt.notificationDetails.amount,
            receiptCount: agency.receiptCount + 1,
          };
          newTopNAgencyDataTransfer[i]=newTopNAgencyData;
          break;
        }
      }
      if(newTopNAgencyData===""){
        this.setState({loading:true})
        Promise.all([this.dashboard().topNAgency(), this.dashboard().weeklyTotals()]).then((response) => {
          this.setState({
            topNAgencyData: response[0].data,
            weeklyData: response[1].data,
            loading: false
          });
        });
      }

      console.log(newTopNAgencyDataTransfer);
      newWeeklyData.receiptTotal[6] = newWeeklyData.receiptTotal[6] + receipt.notificationDetails.amount;
      newWeeklyData.receiptCount[6] = newWeeklyData.receiptCount[6] + 1;
      this.setState({
        topNAgencyData:[...newTopNAgencyDataTransfer],
        weeklyData:{
          ...newWeeklyData,
          selectedDate: weeklyData.selectedDate,
        }
      });
  }

  render() {

    const {loading,MDAConnectionState,weeklyData,topNAgencyData}=this.state;

    if (loading) {
      return (
        <div className="example">
          <Row>
            <Col span={24}>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  marginTop: '150px',
                }}
              >
                <Spin size="large" tip="Loading receipts....." />
              </div>
            </Col>
          </Row>
        </div>
      );
    }

    function updateDashboardData(receipt) {
      const newTopNAgencyData = topNAgencyData.map((agencyData) => {
        if (agencyData.agencyName === receipt.notificationDetails.agencyName) {
          return {
            agencyName: agencyData.agencyName,
            receiptCount: agencyData.receiptCount + 1,
            receiptTotal: receipt.notificationDetails.amount + agencyData.receiptTotal,
          };
        }
        return agencyData;
      });

      /*dashboard.weeklyTotals().then((response) => setWeeklyData(response.data));*/
      /*setTopNAgencyData(newTopNAgencyData);*/
    }

    const convertToAmerican = (data) => {
      let finalData = [];
      for (let i = 0; i < data.length; i++) {
        finalData.push(convertDate(new Date(data[i])));
      }
      return finalData;
    };

    function convertDate(inputFormat) {
      function pad(s) {
        return s < 10 ? '0' + s : s;
      }
      var d = new Date(inputFormat);
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-');
    }

    return (
      <>
        <Row>
          <Col span={12}>
            <Card title="Weekly Totals" style={{ marginRight: '5px', height: '460px' }}>
              <Line
                data={{
                  labels: convertToAmerican(weeklyData.selectedDate),
                  datasets: [
                    {
                      label: 'Weekly totals',
                      backgroundColor: ['#8e5ea2', '#3cba9f', '#e8c3b9', '#c45850'],
                      data: weeklyData.receiptCount,
                    },
                  ],
                }}
                options={{ maintainAspectRatio: true }}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Pie Chart" style={{ marginRight: '5px', height: '460px' }}>
              {topNAgencyData.length ? (
                <C3Chart
                  data={{
                    columns: topNAgencyData.map((agency) => {
                      return [agency.agencyName, agency.receiptCount];
                    }),
                    type: 'pie',
                  }}
                  legend={{ show: true }}
                />
              ) : (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <h5>No Agency has generated any receipts today</h5>
                </div>
              )}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card title="Receipts Summary Data" style={{ marginTop: '10px' }}>
              <Table columns={columns} dataSource={topNAgencyData} rowKey="agencyName" />
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default Dashboard;
