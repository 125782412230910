import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { NavLink } from 'react-router-dom';
import Cookies from 'universal-cookie';

const NavBar = () => {
  const cookie = new Cookies();
  const user = cookie.get('user');
  const isAuthorizedToCreateAgency = user.role.includes('Admin') || user.role.includes('Manager')

  return (
    <div className="hidden bg-blue-600 md:block md:bg-white md:border-b">
      <div className="w-5/6 mx-auto px-4">
        <div className="md:flex">
          <div className="flex -mb-px mr-8">
            <NavLink
              to="/core"
              exact
              activeClassName="md:text-blue-900 border-blue-900"
              className="no-underline text-white opacity-50 md:text-gray-500 md:opacity-100 flex items-center py-4 border-b border-transparent hover:opacity-100 md:hover:border-gray-500 md:hover:text-gray-700"
            >
              <FeatherIcon icon="activity" className="h-5 w-5 mr-2" /> Dashboard
            </NavLink>
          </div>
          {
            isAuthorizedToCreateAgency?
              <div className="flex -mb-px mr-8">
                <NavLink
                  to="/core/agency-management"
                  activeClassName="md:text-blue-900 border-blue-900"
                  className="no-underline text-white opacity-50 md:text-gray-500 md:opacity-100 flex items-center py-4 border-b border-transparent hover:opacity-100 md:hover:border-gray-500 md:hover:text-gray-700"
                >
                  <FeatherIcon icon="folder" className="h-5 w-5 mr-2" /> Agencies
                </NavLink>
              </div>:""
          }
          <div className="flex-mb-px mr-8">
            <NavLink
              to="/core/receipt-management"
              activeClassName="md:text-blue-900 border-blue-900"
              className="no-underline text-white opacity-50 md:text-gray-500 md:opacity-100 flex items-center py-4 border-b border-transparent hover:opacity-100 md:hover:border-gray-500 md:hover:text-gray-700"
            >
              <FeatherIcon icon="file" className="h-5 w-5 mr-2" /> Receipts
            </NavLink>
          </div>
          <div className="flex-mb-px mr-8">
            <NavLink
              to="/core/form-management"
              activeClassName="md:text-blue-900 border-blue-900"
              className="no-underline text-white opacity-50 md:text-gray-500 md:opacity-100 flex items-center py-4 border-b border-transparent hover:opacity-100 md:hover:border-gray-500 md:hover:text-gray-700"
            >
              <FeatherIcon icon="book-open" className="h-5 w-5 mr-2" /> Tokens
            </NavLink>
          </div>
          <div className="flex-mb-px mr-8">
            <NavLink
              to="/core/certificate-management"
              activeClassName="md:text-blue-900 border-blue-900"
              className="no-underline text-white opacity-50 md:text-gray-500 md:opacity-100 flex items-center py-4 border-b border-transparent hover:opacity-100 md:hover:border-gray-500 md:hover:text-gray-700"
            >
              <FeatherIcon icon="book-open" className="h-5 w-5 mr-2" /> Certificates
            </NavLink>
          </div>
          {
            isAuthorizedToCreateAgency?
              <div className="flex-mb-px mr-8">
                <NavLink
                  to="/core/payments"
                  activeClassName="md:text-blue-900 border-blue-900"
                  className="no-underline text-white opacity-50 md:text-gray-500 md:opacity-100 flex items-center py-4 border-b border-transparent hover:opacity-100 md:hover:border-gray-500 md:hover:text-gray-700"
                >
                  <FeatherIcon icon="credit-card" className="h-5 w-5 mr-2" /> Payments
                </NavLink>
              </div>
              :
              ""
          }

          <div className="flex -mb-px mr-8">
            <NavLink
              to="/core/reports"
              activeClassName="md:text-blue-900 border-blue-900"
              className="no-underline text-white opacity-50 md:text-gray-500 md:opacity-100 flex items-center py-4 border-b border-transparent hover:opacity-100 md:hover:border-gray-500 md:hover:text-gray-700"
            >
              <FeatherIcon icon="download" className="h-5 w-5 mr-2" /> Reports
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
