import React from 'react';
import { Row, Col } from 'antd';
import useAxios from 'axios-hooks';
import { BASE_URL } from '../../constants';
import Cookies from 'js-cookie';
import { Tabs } from 'antd';

import LoadingState from '../../components/LoadingState';

import CreateAgencyUser from './components/CreateAgencyUser';
import UpdateAgency from './components/UpdateAgency';
import UpdateAgencyPreferences from './components/UpdateAgencyPreferences';

const { TabPane } = Tabs;

const AgencyReport = props => {
  const {
    match: {
      params: { id },
    },
  } = props;

  const [{ loading: agencyDataLoading, data: agencyData, error }] = useAxios({
    url: `${BASE_URL}/api/agencies/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get('token')}` },
  });

  /*const [{ loading: agencyUsersLoading, data: agencyUsers, agencyUsersError }] = useAxios({
    url: `${BASE_URL}/api/users/agencyUsers/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get('token')}` },
  });*/

  const [createUserShowing, setCreateUserShowing] = React.useState(false);
  const [updateAgencyShowing, setUpdateAgencyShowing] = React.useState(false);
  const [updateAgencyPreferencesShowing, setUpdateAgencyPreferencesShowing] = React.useState(false);
  const [tab, setTab] = React.useState(1);

  if (agencyDataLoading ) {
    return <LoadingState />;
  }

  return (
    <>
      <Tabs defaultActiveKey={tab} onChange={e => console.log(e)}>
        <TabPane tab="Agency Details" key="1">
          <Row style={{ marginBottom: 20, marginTop: 20 }} gutter={10}>
            <Col span={24}>
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                  <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
                    <div className="ml-4 mt-2">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Agency Details
                      </h3>
                    </div>
                    <div className="ml-4 mt-2 flex-shrink-0">
                      <span className="inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() => setUpdateAgencyShowing(true)}
                          type="button"
                          className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-500 focus:outline-none focus:shadow-outline-indigo focus:border-orange-600 mr-5"
                        >
                          Update Agency
                        </button>

                        <button
                          onClick={() => setCreateUserShowing(true)}
                          type="button"
                          className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"
                        >
                          Create Agency User
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm leading-5 font-medium text-gray-500">
                        Name of Agency
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                        {agencyData.name}
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm leading-5 font-medium text-gray-500">Email</dt>
                      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                        {agencyData.emailAddress}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm leading-5 font-medium text-gray-500">Ministry</dt>
                      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                        {agencyData.ministry}
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm leading-5 font-medium text-gray-500">
                        Contact Number
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                        {agencyData.contactNumber}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm leading-5 font-medium text-gray-500">Agency Prefix</dt>
                      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                        {agencyData.prefix}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Agency Preferences" key="2">
          <Row style={{ marginBottom: 20, marginTop: 20 }} gutter={10}>
            <Col span={24} >
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                  <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
                    <div className="ml-4 mt-2">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Agency Preferences
                      </h3>
                    </div>
                    <div className="ml-4 mt-2 flex-shrink-0">
                      <span className="inline-flex rounded-md shadow-sm">
                        <button
                          onClick={() => setUpdateAgencyPreferencesShowing(true)}
                          type="button"
                          className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-500 focus:outline-none focus:shadow-outline-indigo focus:border-orange-600 mr-5"
                        >
                          Update Agency Preferences
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div>
                  <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm leading-5 font-medium text-gray-500">
                        Minimum Unit Threshold
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                        {agencyData.preference.minUnitThreshold}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm leading-5 font-medium text-gray-500">
                        Receipt Length
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                        {agencyData.preference.receiptLength}
                      </dd>
                    </div>
                    {/*<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm leading-5 font-medium text-gray-500">
                        Receipt Type ID
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                        {agencyData.preference.receiptTypeId}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm leading-5 font-medium text-gray-500">
                        Receipt Template Name
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                        {agencyData.preference.receiptTemplateName}
                      </dd>
                    </div>*/}
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm leading-5 font-medium text-gray-500">
                        Issues Email Receipts
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                        {agencyData.preference.emailReceipt ? 'Yes' : 'No'}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm leading-5 font-medium text-gray-500">
                        Issues SMS Receipts
                      </dt>
                      <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                        {agencyData.preference.smsReceipt ? 'Yes' : 'No'}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </Col>
          </Row>
        </TabPane>
      </Tabs>

      <CreateAgencyUser
        isShown={createUserShowing}
        onCloseComplete={() => {
          setCreateUserShowing(false);
        }}
        agencyData={agencyData}
      />

      {updateAgencyShowing ? (
        <UpdateAgency
          isShown={updateAgencyShowing}
          onCloseComplete={() => {
            window.location.reload();
            setUpdateAgencyShowing(false);
          }}
          agencyData={agencyData}
        />
      ) : null}

      {updateAgencyPreferencesShowing ? (
        <UpdateAgencyPreferences
          isShown={updateAgencyPreferencesShowing}
          onCloseComplete={() => {
            window.location.reload();
            setUpdateAgencyPreferencesShowing(false);
          }}
          agencyData={agencyData}
        />
      ) : null}
    </>
  );
};

export default AgencyReport;
