import React, { useState } from 'react';
import { Card, Row, Col } from 'antd';
import { BASE_URL } from '../../constants';
import Hyperlink from '../../components/Hyperlink';
import ManagePayments from './components/ManagePayments';
import PaymentTable from '../../components/PaymentTable';

const Payments = () => {
  const [viewPaymentOpen, setViewPaymentOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState();
  const [submitSuccessful, setSubmitSuccessful] = React.useState(false);

  const columns = [
    {
      title: 'Payment Reference',
      dataIndex: 'paymentGatewayRef',
      key: 'paymentGatewayRef',
      render: text => text || 'N / A',
    },
    {
      title: 'Date',
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: text => <div>{new Date(text).toLocaleDateString()}</div>,
    },
    {
      title: 'Agency Name',
      dataIndex: 'agencyName',
      key: 'agencyName',
    },
    {
      title: 'Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: amount => <span>GHc {amount.toFixed(2)}</span>,
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => {
        if (status === 'Approved') {
          return <p style={{ color: 'green', fontSize: '13px' }}>{status}</p>;
        }

        if (status === 'Declined') {
          return <p style={{ color: '#E53E3E', fontSize: '13px' }}>{status}</p>;
        }

        return <p style={{ color: '#F6AD55', fontSize: '13px' }}>{status}</p>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Hyperlink
            icon="eye"
            onClick={() => {
              setSelectedPayment(record);
              setViewPaymentOpen(true);
            }}
          >
            Manage Payment
            {record.name}
          </Hyperlink>
        </span>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Card title="Payments">
            <PaymentTable
              dataSource={`${BASE_URL}/api/payments/invoices`}
              columns={columns}
              submitSuccessful={submitSuccessful}
            />
          </Card>
        </Col>
      </Row>
      {
        viewPaymentOpen?
          <ManagePayments
            isShown={viewPaymentOpen}
            onCloseComplete={() => {
              setViewPaymentOpen(false);
              setSelectedPayment();
            }}
            onSubmitSuccessful={(data)=>{
              setSubmitSuccessful(data)
            }}
            payment={selectedPayment}
          />:""

      }

    </>
  );
};

export default Payments;
