import React, { useEffect, useState } from 'react';
import useAxios from 'axios-hooks';
import { BASE_URL } from '../../../constants';
import Cookies from 'js-cookie';
import { Col, Row } from 'antd';
import TextInput from '../../../components/TextInput';

function AdditionalFields({ receiptType = '', onValuesChange }) {
  const [{loading, data = [], error}] = useAxios({
    url: `${BASE_URL}/api/gcreceipts/receiptAdditionalFields/${receiptType}`,
    headers: { Authorization: `Bearer ${Cookies.get('token')}` },
  });

  const [additionalFormData, setAdditionalFormData] = useState({

  });

  useEffect(() => {
    onValuesChange(additionalFormData)
  }, [additionalFormData]);

  return (
    <Row gutter={5}>
    {data.map(additionalField => {
        return (
            <Col span={12}>
              <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                {additionalField.fieldName}{additionalField.fieldIsRequired ? '*' : ''}
              </label>
              <div className="rounded-md shadow-sm mb-5">
                <TextInput
                  required={additionalField.fieldIsRequired}
                  value={additionalFormData[additionalField.fieldName]}
                  onChange={e => {
                    setAdditionalFormData({...additionalFormData, [additionalField.fieldName]: e.target.value})
                  }}
                />
              </div>
            </Col>
        )
      })}
    </Row>
  )
}

export default AdditionalFields;