import React, { useEffect } from 'react';
import { SideSheet } from 'evergreen-ui';
import Button from '../../../components/Button';
import TextInput from '../../../components/TextInput';

import useAxios from 'axios-hooks';
import { BASE_URL } from '../../../constants';

import Cookies from 'universal-cookie';
import { toaster } from 'evergreen-ui';
import {Table, Row, Spin, Select} from 'antd';
import fileDownload from "js-file-download";
function ManagePayments({ onCloseComplete, isShown, payment,onSubmitSuccessful }) {
  const cookie = new Cookies();
  const user = cookie.get('user')
  const isAuthorizedToManagePayment = user.role.includes('Admin') || user.role.includes('Manager') || user.role.includes('SuperAdmin')

  const [paymentGatewayRef, setPaymentGatewayRefNo] = React.useState('');
  const [paymentStatus, setPaymentStatus] = React.useState('');

  const [{ loading, data, error }, approvePayment] = useAxios(
    {
      url: `${BASE_URL}/api/payments/${payment ? payment.id : ''}/paymentVerified/${paymentStatus}`,
      headers: { Authorization: `Bearer ${cookie.get('token')}` },
      method: 'put',
      data: {
      },
    },
    { manual: true },
  );
  const [getInvoiceFileLoading, setInvoiceFileLoading] = React.useState(false);

  function getInvoiceFile() {
    (async () => {
      console.log('First start');
      setInvoiceFileLoading(true);
      console.log('First start');
      try {
        const response = await fetch(
          `${BASE_URL}/api/payments/invoices/${payment.id}/invoiceFile`,
          {
            headers: {
              Authorization: `Bearer ${cookie.get('token')}`,
              'Content-Disposition': `attachment; filename=${payment.id}.pdf filename*=${payment.id}.pdf`,
            },
            method: 'get',
          },
        );
        console.log(response);
        const invoiceFile = await response.blob();
        console.log(invoiceFile);
        fileDownload(invoiceFile, `CAGD Invoice ${payment.id}.pdf`);
      } catch (e) {
        console.log(e);
        toaster.danger('Failed to download invoice file');
      }
      setInvoiceFileLoading(false);
    })();
  }

  const url=payment?`${BASE_URL}/api/payments/invoices/${payment.id}`:"";
  const [{ data:paymentData, loading:paymentLoading, error:paymentError },loadData] = useAxios({
    url: url,
    headers: { Authorization: `Bearer ${cookie.get('token')}` }
  });

  useEffect(() => {
    loadData()
  }, []);


  /*if (data) {
    toaster.success('Request is successful', {
      description: 'Payment has been updated',
    });
    window.location.reload()
  }*/

  if (error) {
    toaster.danger('Request failed', {
      description: 'We failed to update payment status',
    });
  }

  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      {payment && !paymentLoading && paymentData ? (
        <div style={{ padding: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Payment Information</h3>
              <p className="max-w-2xl text-sm leading-5 text-gray-500">View and manage payment</p>
            </div>
          </div>
          {
            (payment.status === 'Processing' && isAuthorizedToManagePayment) ? (
          <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">Confirm Payment</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">

                  <div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if(paymentStatus!==""){
                          approvePayment({
                            data: {
                              status: paymentStatus,
                              paymentGatewayRef: payment.paymentGatewayRef,
                              paymentVerified: true,
                            },
                          }).then((data) => {
                            onSubmitSuccessful(data);
                            toaster.success('Invoice successfully updated');
                            setTimeout(() => {
                              onCloseComplete();
                            }, 1000);
                          });
                        }
                        else {
                          toaster.danger('Please select status');
                        }
                      }}
                    >
                      <div>
                        <Select
                          style={{ width: '100%' }}
                          value={paymentStatus}
                          onChange={value =>
                            setPaymentStatus(value)
                          }
                          required
                        >
                          <Select.Option value="">Select</Select.Option>
                          <Select.Option value="Approved">Approved</Select.Option>
                          <Select.Option value="Declined">Declined</Select.Option>
                        </Select>
                        <Button type="submit">
                          {loading ? 'Updating...' : 'Confirm Payment'}
                        </Button>
                      </div>
                    </form>
                  </div>

            </dd>
          </div>
            ) : ""
          }
          <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">Amount</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              GHc {paymentData.totalAmount?paymentData.totalAmount.toFixed(2):""}
            </dd>
          </div>
          <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">Payment Status</dt>
            <dd
              className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
              style={{
                color:
                  paymentData.status === 'Approved'
                    ? 'green'
                    : paymentData.status === 'Declined'
                    ? '#E53E3E'
                    : '#F6AD55',
              }}
            >
              {paymentData.status}
            </dd>
          </div>
          {/*<div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">Payment Verified?</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {paymentData.dataVerified ? 'Yes' : 'No'}
            </dd>
          </div>*/}
          <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">Payment Method</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {paymentData.paymentMethod}
            </dd>
          </div>
          <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">Payment Reference</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">{paymentData.paymentGatewayRef ||"N/A"}</dd>
          </div>
          <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">Payment Made By</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {paymentData.createdBy}
            </dd>
          </div>
          <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">Payment Updated By</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {paymentData.updatedBy || 'N/A'}
            </dd>
          </div>

          <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">Created On</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {new Date(paymentData.createdOn).toDateString()} at{' '}
              {new Date(paymentData.createdOn).toLocaleTimeString()}
            </dd>
          </div>
          <div className="mt-8 sm:grid sm:mt-5 sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <dt className="text-sm leading-5 font-medium text-gray-500">Updated On</dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
              {new Date(paymentData.updatedOn).toDateString()} at{' '}
              {new Date(paymentData.updatedOn).toLocaleTimeString()}
            </dd>
          </div>
          {
            paymentData.paymentDetails?
              <div>
                <Row className="mt-2 border-t border-gray-200 pt-1">
                  {/*<h3 style={{textAlign:'center'}}>Payment Details</h3>*/}
                  <Table
                    pagination={false}
                    dataSource={paymentData.paymentDetails}
                    columns={[{
                      title: 'Book value type',
                      dataIndex: 'valuebookType',
                      key: 'valuebookType',
                    },
                      {
                        title: 'Book value name',
                        dataIndex: 'valuebookName',
                        key: 'valuebookName',
                      },
                      {
                        title: 'Quantity',
                        dataIndex: 'unit',
                        key: 'unit',
                        render: (text, record) => (
                          <span>
                                  {record.unit.toLocaleString()}
                                </span>
                        ),
                      },
                      {
                        title: 'Total Amount',
                        dataIndex: 'totalAmount',
                        key: 'totalAmount',
                        render: (text, record) => (
                          <span>
                                  GHc {record.totalAmount.toLocaleString()}
                                </span>
                        ),
                      }]}
                  />
                </Row>
              </div>
              :
              ""
          }

          {payment.status === 'Processing' && isAuthorizedToManagePayment ? (
            <div style={{ marginTop: '30px' }}>
              {/*<h3>Approve Payment</h3>
              <div>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Payment Reference Number
                </label>
                <TextInput
                  placeholder="Enter Ref No."
                  value={paymentGatewayRef}
                  onChange={(e) => setPaymentGatewayRefNo(e.target.value)}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Payment Status
                </label>
                <Select value={paymentStatus} onChange={(e) => setPaymentStatus(e.target.value)}>
                  <option value="Rejected">Reject</option>
                  <option value="Approved">Approve</option>
                </Select>
              </div>*/}

              {/*<div style={{ marginTop: '30px' }}>
                <Button onClick={approvePayment}>
                  {loading ? 'Loading...' : 'Update Payment Status'}
                </Button>
              </div>*/}
            </div>
          ) : null}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '60px',
            }}
          >
            <Button
              style={{ marginRight: '20px' }}
              onClick={getInvoiceFile}
              disabled={getInvoiceFileLoading}
            >
              {getInvoiceFileLoading ? 'Downloading..' : 'Download as PDF'}
            </Button>
          </div>
        </div>
      ) :
        <div style={{textAlign:'center',marginTop:'50px'}}>
          <h4>Loading payment details....</h4>
          <Spin spinning="true"/>
        </div>
      }
    </SideSheet>
  )
}

export default ManagePayments;
