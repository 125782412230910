import { message as notificationMessage } from 'antd';

const Notification = ({ type, message }) => {
  if (type === 'success') {
    notificationMessage.success(message);
  }

  if (type === 'error') {
    notificationMessage.error(message);
  }
};

export default Notification;
