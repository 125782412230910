import React from 'react';

const Footer = () => {
  return (
    <div className="bg-white border-t" id="footer">
      <div className="container mx-auto px-4">
        <div className="md:flex justify-between items-center text-sm">
          <div className="text-center md:text-left py-3 md:py-4 border-b md:border-b-0">
            <div className="text-grey text-center md:mr-4">CAGD &copy;{new Date().getFullYear()}</div>
          </div>
          <div className="md:flex md:flex-row-reverse items-center py-4">
            <div className="text-grey text-center md:mr-4">Powered by PAQ Technologies V1.0.0</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
