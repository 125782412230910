import React from 'react';
import { Alert, SideSheet } from 'evergreen-ui';
import { Col, Row } from 'antd';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';
import useAxios from 'axios-hooks';
import { BASE_URL, MINISTRIES } from '../../../constants';
import Cookies from 'js-cookie';
import { toaster } from 'evergreen-ui';
import Select from '../../../components/Select';

function UpdateAgency({ onCloseComplete, isShown, agencyData }) {
  const [formData, setFormData] = React.useState({
    name: agencyData.name,
    ministry: agencyData.ministry,
    emailAddress: agencyData.emailAddress,
    contactNumber: agencyData.contactNumber,
    prefix: agencyData.prefix,
  });

  const [{ loading, data, error, response }, updateAgency] = useAxios(
    {
      url: `${BASE_URL}/api/agencies/${agencyData.id}`,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      method: 'put',
      data: {
        ...formData,
      },
    },
    { manual: true },
  );

  if (data) {
    toaster.success('Agency updated successfully');
    onCloseComplete(formData);
  }

  if (response) {
    console.log('This is response', response);
    if (response.status === 204) {
      toaster.success('Agency updated successfully');
      onCloseComplete(formData);
    }
  }

  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      <div style={{ margin: '20px' }}>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Update {agencyData.name}</h3>
        </div>
        <div style={{ marginTop: '40px' }}>
          {error ? (
            <Alert intent="danger">
              <p>{error.response.data}</p>
            </Alert>
          ) : null}

          <h5>Agency Details</h5>
          <form
            className="mt-8"
            onSubmit={e => {
              e.preventDefault();
              updateAgency();
            }}
          >
            <Row gutter={5}>
              <Col span={8}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Prefix
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextInput
                    required
                    placeholder="Prefix"
                    value={formData.prefix}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        prefix: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <Col span={16}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Name
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextInput
                    required
                    placeholder="Prefix"
                    value={formData.name}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <Col span={12}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Email
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextInput
                    required
                    placeholder="example@agency.com"
                    type="email"
                    value={formData.emailAddress}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        emailAddress: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <Col span={12}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Contact Number
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextInput
                    required
                    placeholder="+233558691496"
                    type="tel"
                    value={formData.contactNumber}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        contactNumber: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <Col span={24}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Ministry
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <Select
                    value={formData.ministry}
                    onChange={e => setFormData({ ...formData, ministry: e.target.value })}
                  >
                    {MINISTRIES.map(item => {
                      return <option value={item}>{item}</option>;
                    })}
                  </Select>
                </div>
              </Col>

              <Col span={24}>
                <Button type="submit">{loading ? 'Loading..' : 'Update Agency'}</Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </SideSheet>
  );
}

export default UpdateAgency;
