import React, { useState, useEffect } from 'react';
import { Table, Pagination } from 'antd';
import useAxios from 'axios-hooks';
import Cookies from 'js-cookie';
import { Input } from 'antd';
import debounce from '../utils/debounce';
import { Button, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { BASE_URL } from '../constants';

const GeneralReportTable = ({ columns, dataSource }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [filterValue, setFilterValue] = useState('');

  const [{ data, loading, error }, loadData] = useAxios(
    {
      url: `${dataSource}?PageNumber=${pageNumber}&PageSize=${pageSize}&ReportType=Admin`,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
    },
    { manual: true },
  );


  const handlePagination = (pagination) => {
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);
  };


  useEffect(() => {
    loadData().then(response => {
      if (response) {
        console.log((JSON.parse(response.headers.pagination)).currentPage);
        setPageNumber((JSON.parse(response.headers.pagination)).currentPage);
        setPagination(JSON.parse(response.headers.pagination));
      }
    });
  }, [pageNumber,pageSize]);

  const loadDataEffectively=debounce(()=>{
    setPageNumber(1);
    loadData().then(response => {
      if (response) {
        console.log((JSON.parse(response.headers.pagination)).currentPage);
        setPageNumber((JSON.parse(response.headers.pagination)).currentPage);
        setPagination(JSON.parse(response.headers.pagination));
      }
    });
  }, 0);

  /*if (data){

  }*/
  if (error){
    console.log(error);
  }
  return (
    <>
      {/*<div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginBottom: '20px',
        }}
      >
        <Input
          value={filterValue}
          placeholder="Type a keyword"
          style={{ width: '30%',marginRight:'5px' }}
          onChange={(e) => {
            setFilterValue(e.target.value)
          }}
        />
        <Tooltip title="search">
          <Button
            onClick={loadDataEffectively}
            type="primary"
            shape="circle" icon={<SearchOutlined />} />
        </Tooltip>
      </div>
*/}
      <Table columns={columns} loading={loading} dataSource={data} onChange={handlePagination}
             pagination={{ pageSize: pageSize, current: pageNumber, total: pagination.totalItems, pageSizeOptions: ["10", "20", "30", "50"], showSizeChanger: true, }}
      />
    </>
  );
};

export default GeneralReportTable;
