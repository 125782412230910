import React from 'react';
import { Col, Row, Spin } from 'antd';

function LoadingState({ message }) {
  return (
    <div>
      <Row>
        <Col span={24}>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              marginTop: '150px',
            }}
          >
            <Spin size="large" tip={message || 'Loading agencies.....'} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default LoadingState;
