import React, { useState, useEffect } from 'react';
import { Table, Pagination, DatePicker, Select, Tooltip, Button } from 'antd';
import useAxios from 'axios-hooks';
import Cookies from 'js-cookie';
import { Input,Row,Col } from 'antd';
import 'semantic-ui-css/semantic.min.css'
import debounce from '../utils/debounce';
import { SearchOutlined } from '@ant-design/icons';

const PaymentTable = ({ columns, dataSource ,submitSuccessful}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [filterValue, setFilterValue] = useState({
    agencyName: '',
    agencyId: '',
    startDate: new Date().toDateString(),
    onlyDate: 'false',
  });

  const [{ data, loading }, loadData] = useAxios(
    {
      url: `${dataSource}?PageNumber=${pageNumber}&PageSize=${pageSize}&SearchQuery=${filterValue.agencyName}&Created=${filterValue.startDate}&SpecifiedDateOnly=${filterValue.onlyDate}`,
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}` ,
        'Content-Type': 'application/json',
      },
      data:{},
      method:"get",
    },
    { manual: true },
  );

  useEffect(() => {
    loadDataEffectively()
  }, [submitSuccessful,pageSize,pageNumber]);

  const loadDataEffectively=
    debounce(() => {
      loadData({
        url: `${dataSource}?PageNumber=${pageNumber}&PageSize=${pageSize}&SearchQuery=${filterValue.agencyName}&Created=${filterValue.startDate}&AgencyId=${filterValue.agencyId}&SpecifiedDateOnly=${filterValue.onlyDate}`,
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}` ,
          'Content-Type': 'application/json',
        },
        data:{},
        method:"get",
      }).then(response => {
        setPagination(JSON.parse(response.headers.pagination));
      });
    }, 0);

  const onChange = (date, dateString) => {
    setPageNumber(1);
    setFilterValue({
      ...filterValue,
      startDate: dateString,
    });
  };

  const handlePagination = (pagination) => {
    console.log(pagination);
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <>
      <Row gutter={6}>
        <Col span={12}>
          <Input
            value={filterValue.agencyName}
            placeholder="Search by Agency Name"
            style={{ width: '100%%', marginRight: '10px' }}
            onChange={(e) =>{
              setFilterValue({
                ...filterValue,
                agencyName: e.target.value,
              })
            }}
          />
        </Col>
        <Col span={7}>
          <DatePicker
            style={{
              width: '100%',
            }}
            onChange={onChange}
          />
        </Col>
        <Col span={4}>
          <Select
            style={{ width: '100%' }}
            value={filterValue.onlyDate}
            onChange={value =>{
              setFilterValue({
                ...filterValue,
                onlyDate: value,
              })
            }}
          >
            <Select.Option value="false">Do not show specific date</Select.Option>
            <Select.Option value="true">Show specific date</Select.Option>
          </Select>
        </Col>
        <Col span={1}>
        <Tooltip title="search">
          <Button
            style={{marginLeft:'5px'}}
            onClick={()=>{
              setPageNumber(1);
              loadDataEffectively()
            }}
            type="primary"
            shape="circle" icon={<SearchOutlined />} />
        </Tooltip>
        </Col>
      </Row>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '20px',
          }}
        >
          {/*<Input
            value={filterValue.agencyId}
            placeholder="Search Agency ID"
            style={{ width: '20%', marginRight: '10px' }}
            onChange={e =>
              setFilterValue({
                ...filterValue,
                agencyId: e.target.value,
              })
            }
          />*/}


          <div>

          </div>
          <div style={{ width: '30%' }}>

          </div>
        </div>
      </div>

      <Table columns={columns} loading={loading} dataSource={data}
             onChange={handlePagination}
             pagination={{
        pageSize: pageSize,
        current: pageNumber,
        total: pagination.totalItems,
        pageSizeOptions: ["10", "20", "30", "50"],
        showSizeChanger: true,
      }}
      />
    </>
  );
};

export default PaymentTable;
