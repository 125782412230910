import React from 'react';
import FeatherIcon from "feather-icons-react";


const Container = ({ children }) =>{
    return (
        <div className="flex-grow  mx-auto sm:px-4 pt-6 pb-8 w-10/12" >
            {children}
        </div>
    );
};

export default Container;
