import React, { useEffect } from 'react';
import { Alert, SideSheet } from 'evergreen-ui';
import { Col, Row, Spin } from 'antd';
import TextInput from '../../components/TextInput';
import Select from '../../components/Select';
import TextArea from '../../components/TextArea';
import Button from '../../components/Button';
import useAxios from 'axios-hooks';
import { BASE_URL } from '../../constants';
import Cookies from 'js-cookie';
import { toaster } from 'evergreen-ui';
import AdditionalFields from './components/AdditionalFields';

const initialState = {
  agencyUniqueReference: '',
  receiptDescription: '',
  recipientName: '',
  recipientPhone: '',
  recipientEmail: '',
  receiptType: '',
  amount: 0,
};

function IssueCertificate({ onCloseComplete, isShown }) {
  const [formData, setFormData] = React.useState(initialState);

  const [
    { loading: receiptTypesLoading, data: receiptTypesData = [], error: receiptTypesError },
  ] = useAxios({
    url: `${BASE_URL}/api/gcreceipts/agencyReceiptType`,
    headers: { Authorization: `Bearer ${Cookies.get('token')}` },
  });

  const [{ loading, data, error }, registerUser] = useAxios(
    {
      url: `${BASE_URL}/api/gcreceipts/createReceiptOnly`,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      method: 'post',
      data: {
        ...formData,
      },
    },
    { manual: true },
  );

  React.useEffect(() => {
    console.log('the new data came through...', receiptTypesData);
    if (receiptTypesData.length) {
      setFormData({
        ...formData,
        receiptType: receiptTypesData[0].receiptType,
      });
    }
  }, [receiptTypesData]);

  if (error) {
    toaster.danger('We failed to issue this receipt', {
      description: error.response.data,
    });
  }

  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      <div style={{ margin: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Issue a receipt</h3>
            Enter customer and payment details to issue receipt
          </div>

          <Spin spinning={receiptTypesLoading} />
        </div>
        <div style={{ marginTop: '40px' }}>
          {error ? (
            <Alert intent="danger">
              <p>{error.response.data}</p>
            </Alert>
          ) : null}
          <form
            className="mt-8"
            onSubmit={e => {
              e.preventDefault();
              registerUser().then(() => {
                toaster.success('Receipt issued successfully');
                setFormData(initialState);
                setTimeout(() => {
                  onCloseComplete();
                }, 1000);
              });
            }}
          >
            <Row gutter={5}>
              <Col span={12}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Agency Unique Reference
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextInput
                    value={formData.agencyUniqueReference}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        agencyUniqueReference: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <Col span={12}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Receipt Type
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <Select onChange={e => setFormData({ ...formData, receiptType: e.target.value })}>
                    {receiptTypesData.map(({ receiptType }) => {
                      return <option value={receiptType}>{receiptType}</option>;
                    })}
                  </Select>
                </div>
              </Col>

              <Col span={12}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Recipient Name*
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextInput
                    required
                    placeholder="Kofi Ghana"
                    type="text"
                    value={formData.recipientName}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        recipientName: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <Col span={12}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Recipient Email
                </label>
                <div className="mb-5">
                  <div className="rounded-md shadow-sm">
                    <TextInput
                      placeholder="kofighana@emailaddress.com"
                      type="email"
                      value={formData.recipientEmail}
                      onChange={e =>
                        setFormData({
                          ...formData,
                          recipientEmail: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </Col>

              <Col span={12}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Recipient Phone
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextInput
                    placeholder="+233558691496"
                    type="tel"
                    value={formData.recipientPhone}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        recipientPhone: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <Col span={12}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Amount*
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextInput
                    required
                    type="number"
                    value={formData.amount}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        amount: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <AdditionalFields
                receiptType={formData.receiptType}
                onValuesChange={values =>
                  Object.keys(values).length
                    ? setFormData({
                        ...formData,
                        additionalFields: values,
                      })
                    : null
                }
              />

              <Col span={24}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Receipt Description*
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextArea
                    required
                    type="text"
                    value={formData.receiptDescription}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        receiptDescription: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <Col span={24}>
                {loading ? (
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <Spin />
                  </div>
                ) : (
                  <Button type="submit">Issue Receipt</Button>
                )}
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </SideSheet>
  );
}

export default IssueCertificate;
