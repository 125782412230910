import React from 'react';
import { Alert, SideSheet } from 'evergreen-ui';
import { Col, Row } from 'antd';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';
import useAxios from 'axios-hooks';
import { BASE_URL } from '../../../constants';
import Cookies from 'js-cookie';
import { toaster } from 'evergreen-ui';

function CreateAgencyUser({ onCloseComplete, isShown, agencyData }) {
  const [formData, setFormData] = React.useState({
    email: '',
    password: '',
    phoneNumber: '',
    fullName: '',
  });

  const [{ loading, data, error }, registerUser] = useAxios(
    {
      url: `${BASE_URL}/api/auth/adminRegisterUser`,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
      method: 'post',
      data: {
        ...formData,
        agencyId: agencyData.id,
      },
    },
    { manual: true },
  );

  if (data) {
    toaster.success('User created successfully', {
      description: 'An administrator account has been created for ' + agencyData.name,
    });
    onCloseComplete();
  }

  return (
    <SideSheet isShown={isShown} onCloseComplete={onCloseComplete}>
      <div style={{ margin: '20px' }}>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Register users for {agencyData.name}
          </h3>
          Enter users details to create a new user..
        </div>
        <div style={{ marginTop: '40px' }}>
          {error ? (
            <Alert intent="danger">
              <p>{error.response.data}</p>
            </Alert>
          ) : null}
          <form
            className="mt-8"
            onSubmit={e => {
              e.preventDefault();
              registerUser();
            }}
          >
            <Row gutter={5}>
              <Col span={24}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Full Name
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextInput
                    required
                    placeholder="Full Name"
                    value={formData.fullName}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        fullName: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <Col span={12}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Email
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextInput
                    required
                    placeholder="example@agency.com"
                    type="email"
                    value={formData.email}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <Col span={12}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Phone Number
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextInput
                    required
                    placeholder="+233558691496"
                    type="tel"
                    value={formData.phoneNumber}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        phoneNumber: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <Col span={24}>
                <label className="block capitalize tracking-wide text-grey-darker text-xs font-bold mb-2">
                  Password
                </label>
                <div className="rounded-md shadow-sm mb-5">
                  <TextInput
                    required
                    type="password"
                    value={formData.password}
                    onChange={e =>
                      setFormData({
                        ...formData,
                        password: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>

              <Col span={24}>
                <Button type="submit">{loading ? 'Loading..' : 'Create User'}</Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </SideSheet>
  );
}

export default CreateAgencyUser;
