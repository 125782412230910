import React, { useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Table, Pagination, DatePicker, Select, Tooltip, Button } from 'antd';
import useAxios from 'axios-hooks';
import Cookies from 'js-cookie';
import { Input } from 'antd';
import debounce from '../utils/debounce';
import moment from 'moment';

const { RangePicker } = DatePicker;

const CertificateTable = ({ columns, dataSource }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pagination, setPagination] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [filterValue, setFilterValue] = useState({
    searchQuery: '',
    receiptNumber: '',
    agencyUniqueReference: '',
    startDate: new Date().toDateString(),
    endDate: new Date().toDateString(),
  });

  const handlePagination = (pagination) => {
    setPageNumber(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const [{ data, loading }, loadData] = useAxios(
    {
      url: `${dataSource}?PageNumber=${pageNumber}&PageSize=${pageSize}`,
      headers: { Authorization: `Bearer ${Cookies.get('token')}` },
    },
    { manual: true },
  );

  const efficientlyLoadData = debounce(() => {
    setPageNumber(1);
    loadData({
      url: `${dataSource}?PageNumber=${pageNumber}&PageSize=${pageSize}&SearchQuery=${filterValue.searchQuery}&ReceiptNumber=${filterValue.receiptNumber}&AgencyUniqueReference=${filterValue.agencyUniqueReference}&StartDate=${filterValue.startDate}&EndDate=${filterValue.endDate}`,
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
        'Content-Type': 'application/json',
      },
      data: {}, // This is a hack. We pass empty data object else axios will remove the Content-Type header from our request
    }).then((response) => {
      if (response && response.headers) {
        setPagination(JSON.parse(response.headers.pagination));
      }
    });
  }, 0);
  useEffect(() => {
    loadData({
      url: `${dataSource}?PageNumber=${pageNumber}&PageSize=${pageSize}&SearchQuery=${filterValue.searchQuery}&ReceiptNumber=${filterValue.receiptNumber}&AgencyUniqueReference=${filterValue.agencyUniqueReference}&StartDate=${filterValue.startDate}&EndDate=${filterValue.endDate}`,
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
        'Content-Type': 'application/json',
      },
      data: {}, // This is a hack. We pass empty data object else axios will remove the Content-Type header from our request
    }).then((response) => {
      if (response && response.headers) {
        setPagination(JSON.parse(response.headers.pagination));
      }
    });
  }, [pageNumber,pageSize]);

  const onChange = (date, dateString) => {
    setFilterValue({
      ...filterValue,
      startDate: dateString[0],
      endDate: dateString[1],
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '20px',
          justifyContent: 'flex-end',
        }}
      >
        <Input
          value={filterValue.receiptNumber}
          placeholder="Search Token Number"
          style={{ width: '20%', marginRight: '10px' }}
          onChange={(e) =>{
            setFilterValue({
              ...filterValue,
              receiptNumber: e.target.value,
            })
          }}
        />

        <Input
          value={filterValue.agencyUniqueReference}
          placeholder="Search by Agency Unique Reference"
          style={{ width: '30%', marginRight: '10px' }}
          onChange={(e) =>{
            setFilterValue({
              ...filterValue,
              agencyUniqueReference: e.target.value,
            })
          }}
        />

        <Input
          value={filterValue.searchQuery}
          placeholder="Search by customer name"
          style={{ width: '30%', marginRight: '10px' }}
          onChange={(e) =>{
            setFilterValue({
              ...filterValue,
              searchQuery: e.target.value,
            })
          }}
        />

        <div>
          <DatePicker
            placeholder={"Date From"}
            onChange={(e) =>{
              setFilterValue({
                ...filterValue,
                startDate: e.format('YYYY-M-D'),
              })
            }}
          />
        </div>

        <div>
          <DatePicker
            placeholder={"Date To"}
            format="YYYY-MM-DD"
            onChange={(e) =>{
              setFilterValue({
                ...filterValue,
                endDate: e.format('YYYY-M-D'),
              })
            }}
          />


          {/*<input
            type={'date'}
            value={filterValue.searchQuery}
            placeholder="Date from"
            style={{ width: '100%', marginRight: '10px' }}
            onChange={(e) =>{
              setFilterValue({
                ...filterValue,
                searchQuery: e.target.value,
              })
            }}
          />*/}
          {/*<RangePicker
            style={{
              width: '100%',
            }}
            onChange={onChange}
          />*/}
        </div>
        <Tooltip title="search">
          <Button
            style={{marginLeft:'5px'}}
            onClick={efficientlyLoadData}
            type="primary"
            shape="circle" icon={<SearchOutlined />} />
        </Tooltip>
      </div>

      <Table columns={columns} loading={loading} dataSource={data} onChange={handlePagination}
             pagination={{
               pageSize: pageSize,
               current: pageNumber,
               total: pagination.totalItems,
               pageSizeOptions: ["10", "20", "30", "50"],
               showSizeChanger: true,
             }}
      />
    </>
  );
};

export default CertificateTable;
