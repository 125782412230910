import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toaster, Alert } from 'evergreen-ui';
import useAxios from 'axios-hooks';

// images
import coatOfArms from '../assets/images/coatofarms.svg';

// components
import Button from '../components/Button';
import TextInput from '../components/TextInput';
import Hyperlink from '../components/Hyperlink';

// constants
import { RESET_PASSWORD_URL } from '../constants';

function ResetPassword() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const [{ loading }, resetPassword] = useAxios(
    {
      url: RESET_PASSWORD_URL,
      method: 'put',
      data: {
        email,
      },
    },
    { manual: true },
  );

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div>
          <img className="mx-auto h-12 w-auto" src={coatOfArms} alt="Coat of Arms Logo" />
          <h3 className="mt-6 text-center text-3xl">Reset Password</h3>
          <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
            We will send you an email with instructions to reset your password
          </p>
        </div>
        {success ? (
          <Alert
            intent="success"
            title="Password reset instructions have been sent to your email"
            marginBottom={32}
          >
            Please follow the instructions to complete the password reset process. This page will be
            redirected in 5 seconds
          </Alert>
        ) : null}
        <form className="mt-8" onSubmit={onSubmitLogin}>
          <div className="rounded-md shadow-sm">
            <TextInput
              aria-label="Email address"
              name="email"
              type="email"
              required
              placeholder="Email address"
              onChange={e => setEmail(e.target.value)}
            />
          </div>

          <div className="mt-6">
            <Button disabled={loading}>
              {loading ? 'Loading...' : 'Reset Password'}
            </Button>
          </div>

          <div className="mt-6 align-center">
            <Hyperlink to="/">Go back to login</Hyperlink>
          </div>
        </form>
      </div>
    </div>
  );

  async function onSubmitLogin(e) {
    e.preventDefault();
    try {
      const response = await resetPassword();
      setSuccess(true);
      setTimeout(() => {
        history.push('/');
      }, 5000);
    } catch (e) {
      toaster.danger('Your request failed', {
        description: 'We failed to send the request to reset your password. Please try again',
      });
    }
  }
}

export default ResetPassword;
