import React, { useState } from 'react';
import { Card, Row, Col } from 'antd';
import ViewReceipt from './components/ViewReceipt';
import { BASE_URL } from '../../constants';
import ReceiptTable from '../../components/ReceiptTable';
import Hyperlink from '../../components/Hyperlink';

const Receipt = () => {
  const [viewReceiptOpen, setViewReceiptOpen] = useState(false);
  const [selectedReceipt, selectReceipt] = useState({});

  const columns = [
    {
      title: 'Receipt No.',
      dataIndex: 'receiptNumber',
      key: 'receiptNumber',
    },
    {
      title: 'Agency',
      dataIndex: 'agencyName',
      key: 'agencyName',
    },
    {
      title: 'Agency Ref. No.',
      dataIndex: 'agencyUniqueReference',
      key: 'agencyUniqueReference',
      render: (text) => (!text ? 'N / A' : text),
    },
    {
      title: 'Date',
      dataIndex: 'receiptDate',
      key: 'receiptDate',
      render: text => <div>{new Date(text).toLocaleDateString()}</div>,
    },

    {
      title: 'Recipient Name',
      dataIndex: 'recipientName',
      key: 'recipientName',
    },
    {
      title: 'Recipient Email',
      dataIndex: 'recipientEmail',
      key: 'recipientEmail',
    },
    {
      title: 'Recipient Phone',
      dataIndex: 'recipientPhone',
      key: 'recipientPhone',
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: amount => <span>GHc {Number(amount).toFixed(2)}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Hyperlink
            icon="eye"
            onClick={() => {
              selectReceipt(record);
              setViewReceiptOpen(true);
            }}
          >
            View Receipt
            {record.name}
          </Hyperlink>
        </span>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Card title="Generated Receipts">
            <ReceiptTable
              dataSource={`${BASE_URL}/api/gcreceipts`}
              columns={columns}
            />
          </Card>
        </Col>
      </Row>

      <ViewReceipt
        isShown={viewReceiptOpen}
        receipt={selectedReceipt}
        onCloseComplete={() => setViewReceiptOpen(false)}
        width="500px"
      />
    </>
  );
};

export default Receipt;
