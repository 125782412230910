import React, { useState,createRef  } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Header from './components/Header';
import NavBar from './components/NavBar';
import Container from './components/Container';
import Dashboard from './Dashboard';
import Agencies from './pages/Agencies/Agency';
import RegisterAgency from './pages/Agencies/RegisterAgency';
import Footer from './components/Footer';
import SignIn from './pages/SignIn';
import Receipt from './pages/Receipts/Receipt';
import Certificate from './pages/Certificates/Certificate';
import Form from './pages/Forms/Form';
import Report from './pages/Reports/GeneralReports';
import Payments from './pages/Payments/Payments';
import AgencyReport from './pages/Agencies/AgencyReport';
import ResetPassword from './pages/ResetPassword';
import Settings from './pages/Settings/Settings';
import * as SignalR from '@aspnet/signalr';
import loader from './assets/images/ajax-loader.gif';
import {Button} from 'antd'
import { REACT_APP_API_MDA_WEBSOCKET_BASE_URL } from './constants';
import Cookies from 'universal-cookie';
import IdleTimer from 'react-idle-timer'

const connection = new SignalR.HubConnectionBuilder()
  .withUrl(REACT_APP_API_MDA_WEBSOCKET_BASE_URL)
  .withAutomaticReconnect([0, 2000,3000,5000,10000, null])
  .configureLogging(SignalR.LogLevel.Information)
  .build();

async function start() {
  //console.log('status ',connection.state);
    try {
      await connection.start();
      //console.log("connected");
    } catch (err) {
      console.log(err);
      /*setTimeout(() => start(), 5000);*/
    }

}

const cookies = new Cookies();

const inputRef = createRef(null);

class Core extends React.Component {
  constructor(props,context) {
    super(props,context);
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.state = {
      logout:false,
      listnerAdded:false,
      MDAConnectionState:"reconnecting",
      portalConnectionState:false,
    };
  }

  componentDidMount(){
    console.log(this.state.MDAConnectionState);
    if ( connection.state==="Disconnected" ) {
      start().then(() => {
        if (connection.state === "Connected") {
          connection.on('mda', (receipt) => {
            this.triggerChildAlert(receipt);
          });

          this.setState({ MDAConnectionState: 'connected' });
          console.log(this.state.MDAConnectionState);
          connection.onclose(error => {
            this.setState({ MDAConnectionState: "disconnected" });
            console.log('Connection closed due to error. Try refreshing this page to restart the connection', error);
          });

          connection.onreconnecting(error => {
            this.setState({ MDAConnectionState: "reconnecting" });
            console.log('Connection lost due to error. Reconnecting.', error);
          });

          connection.onreconnected(connectionId => {
            this.setState({ MDAConnectionState: "connected" });
            console.log('Connection reestablished. Connected with connectionId', connectionId);
          });
        }
      }).catch((err) => {
        this.setState({ MDAConnectionState: "reconnecting" });
      });
    }
    else {
      this.setState({ MDAConnectionState: 'connected' });
    }
  }

  triggerChildAlert(receipt){
    inputRef.current.addMDAListner(receipt);
  }

  setAdded(value){
    this.setState({'listnerAdded':value})
  }

  render(){

    const {logout,portalConnectionState,MDAConnectionState}=this.state;

    if (logout) {
      cookies.remove('token');
      return <Redirect to="/" />;
    }


    return (

      <div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 60}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        {
          MDAConnectionState==='connected'?
            <div className="font-sans font-light text-sm bg-gray-200 flex flex-col min-h-screen w-full">
              <div id="header">
                <div className="md:flex text-right">
                  <h4
                    style={{
                      pull: 'right',
                      textAlign: 'right',
                      float: 'right',
                      color: MDAConnectionState==='connected' ? 'green' : 'red',
                    }}
                  >
                    {MDAConnectionState==='connected' ? 'Online' :
                      MDAConnectionState==='disconnected'?'Connection closed due to error. Try refreshing this page to restart the connection'
                        : 'Connecting...'}
                  </h4>
                </div>
                <Header />
                <NavBar />
              </div>
              <Container>
                <Switch>
                  <Route exact path="/core" component={() => <Dashboard  ref={inputRef} setAdded={this.setAdded} listnerAdded={this.state.listnerAdded} connection={connection} />}/>
                  <Route exact path="/core/agency-management" component={Agencies} />
                  <Route exact path="/core/certificate-management" component={Certificate} />
                  <Route exact path="/core/agency-management/create-agency" component={RegisterAgency} />
                  <Route exact path="/core/agency-management/:id" component={AgencyReport} />
                  <Route exact path="/core/receipt-management" component={Receipt} />
                  <Route exact path="/core/form-management" component={Form} />
                  <Route exact path="/core/payments" component={Payments} />
                  <Route exact path="/core/reports" component={Report} />
                  <Route exact path="/core/settings" component={Settings} />
                </Switch>
              </Container>
              <Footer />
            </div>:
            <>
            <div style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              marginTop: '-50px',
              marginLeft: '-100px'
            }}>
              <img align="center" src={loader} alt="loader" />
            </div>
              <div style={{
                position: 'fixed',
                top: '54%',
                left: '50%',
                marginTop: '-50px',
                marginLeft: '-100px'
              }}>
                <h4
                  style={{
                    textAlign: 'center',
                    color: MDAConnectionState==='connected' ? 'green' : 'red',
                  }}
                >
                  {MDAConnectionState==='connected' ? 'Online' :
                    MDAConnectionState==='disconnected'? <a href="/#/core"><Button>Refresh here page</Button></a>
                      :'Connecting....'
                  }
                </h4>
              </div>
            </>
        }

      </div>
    );
  }


  handleOnAction (event) {
    console.log('user did something', event)
  }

  handleOnActive (event) {
    console.log('user is active', event);
    console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle (event) {
    console.log('last active', this.idleTimer.getLastActiveTime())
    cookies.remove('token');
    window.location.href='/';
  }
};

function App(props) {
  console.log("props here ",props);
  return (
    <>
      <Switch>
        <Route path="/core" component={() => <Core />}/>
      </Switch>
      <Switch>
        <div className=" flex flex-col">
          <Route exact path="/" component={SignIn} />
          <Route exact path="/reset-password" component={ResetPassword} />
        </div>
      </Switch>
    </>
  );
}

export default App;
