const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_APP_API_MDA_WEBSOCKET_BASE_URL = process.env.REACT_APP_API_MDA_WEBSOCKET_BASE_URL;
const LOGIN_URL = `${BASE_URL}/api/auth/adminLogin`;
const RESET_PASSWORD_URL = `${BASE_URL}/api/auth/forgotPassword`;
const GET_RECEIPTS_URL = `${BASE_URL}/api/gcreceipts`;

const MINISTRIES = [
  'Ministry of Trade and Industry',
  'Ministry of Finance',
  'Ministry of Defence',
  'Ministry of Interior',
  'Ministry of Energy and Petroleum',
  'Office of Attorney General and Ministry of Justice',
  'Ministry of Foreign Affairs',
  'Ministry of Food and Agriculture',
  'Ministry of Education',
  'Ministry of Health',
  'Ministry of Monitoring and Evaluation',
  'Ministry of Regional Reorganization and Development',
  'Ministry of Lands and Natural Resources',
  'Ministry of Railway Development',
  'Ministry of Employment and Labour Relations',
  'Ministry of Transport',
  'Ministry of Tourism, Culture and Creative Arts',
  'Ministry of Special Development Initiative',
  'Ministry of Environment, Science and Technology',
  'National Security Ministry',
  'Ministry of Youth and Sports',
  'Ministry of Local Government and Rural Development',
  'Ministry of Works and Housing',
  'Ministry of Communication',
  'Ministry of Information',
  'Ministry of Roads and Highways',
  'Ministry of Gender, Children and Social Protection',
  'Ministry of Planning',
  'Ministry of Fisheries and Aquaculture',
  'Ministry of Chieftancy and Religious Affairs',
  'Ministry of Inner Cities and Zongo Development',
  'Ministry of Business Development',
  'Ministry of Parliamentary Affairs',
];

export { BASE_URL, MINISTRIES, LOGIN_URL, GET_RECEIPTS_URL,REACT_APP_API_MDA_WEBSOCKET_BASE_URL, RESET_PASSWORD_URL };
